@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.wishlist {
  &--content {
    background-color: $col-white;
    padding: 40px;
    height: auto;
    width: 551px;
    @include column;
    gap: 20px;
    border-radius: 5px;
    max-height: 85vh;
    overflow-y: auto;
    padding: 40px;

    @include respond(phone) {
      padding: 48px 32px;
      max-height: auto;
      height: 100vh;
      height: -moz-available;
      height: -webkit-fill-available;
      height: calc(100vh - calc(100vh - 100%));
      width: 100vw;
      width: -moz-available;
      width: -webkit-fill-available;
      border-radius: 0px;
      @include column;
      gap: 0.75rem;
      @include z-index(fill-page);
    }

    &__btn {
      font-size: 16px;
      width: 100%;
      padding: 12px 32px;
      background: #fff8e6;
      color: $col-black;
      border: 1px solid #ffb800;
      border-radius: 4px;
      @include flex-row;
      @include border-radius(4px);
      @include transition;

      & span {
        color: $col-black;
        font-family: $Tiempos;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
      }
      &:disabled,
      &[disabled] {
        background: #cccccc;
        border: 1px solid #cccccc;
        cursor: not-allowed;

        & span {
          color: $col-white;
        }

        &:hover,
        &:focus-within {
          background: #cccccc;
          border: 1px solid #cccccc;

          & span {
            color: $col-white;
          }
        }
      }

      // &:hover,
      // &:focus-within {
      // }
    }

    &__top {
      @include column;
      gap: 0.75rem;
      margin-bottom: 16px;

      h1 {
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
      }
    }

    &__scroll {
      width: 100%;
      height: 400px;
      overflow: hidden;

      @include respond(phone) {
        height: 80vh;
      }

      &__bar {
        width: 8px;
        background: #fff8e6;
        border-radius: 30px;
        height: 100%;
      }

      &__thumb {
        width: 8px;
        height: 26px;
        background: #ffe299;
        border-radius: 30px;
      }
    }

    &__bottom {
      @include column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
    }

    &__item {
      &__outer {
        @include flex-row;
        justify-content: space-between;
        align-items: center;
        background: #fafafa;
        margin-bottom: 0.5rem;
        margin-right: 1.2rem;
        padding: 16px 20px;
        flex-wrap: wrap;

        @include respond(phone) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }

      &__download {
        @include flex-row;
        align-items: center;
        padding: 8px 8px 8px 0;
        white-space: nowrap;
        flex: 1;
        gap: 8px;
      }

      &__link {
        @include flex-row;
        align-items: center;
      }

      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      color: $col-black;
      flex: 1;
      max-width: 88vw;
      overflow: hidden;
      flex-wrap: wrap;
      border-radius: 4px;

      @include respond(phone) {
        max-width: none;
      }

      &__left {
        width: 70%;
        overflow: hidden;

        @include respond(phone) {
          width: 100%;
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #4d4d4d;
          width: 100%;
          margin-bottom: 0.5rem;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        h2 {
          font-weight: 400;
          font-size: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 30px;
          color: #4d4d4d;
        }
      }

      &__right {
        width: fit-content;
      }
    }

    &-form {
      &_container {
        margin-bottom: 2rem;
        @include grid-row(auto-fill, auto);
        grid-row-gap: 18px;
      }
    }
    &-btnContainer {
      display: flex;
      div {
        margin-left: auto;
        button {
          width: auto;
        }
      }
    }
  }
}
