@import "@styles/abstracts/variables.scss";
@import "@styles/abstracts/mixins.scss";

.home {
  &__slider {
    background: #fff;
    height: calc(100vh - 64px);
    width: 100%;
    position: relative;
    overflow: hidden;

    & img {
      object-fit: cover !important;
    }

    &-image {
      top: 0;
      position: relative;
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
      right: 0;

      & img {
        object-fit: cover !important;
      }
    }

    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) -37.03%,
        rgba(0, 0, 0, 0.62) 100%
      );
    }

    &-indicator {
      @include flex-row;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      margin-bottom: 24px;
      z-index: 4;

      &-item {
        width: 45px;
        height: 5px;
        background-color: #808080;
        @include border-radius(100px);
        margin-right: 7px;

        &:last-child {
          margin-right: 0px;
        }

        &[data-active="true"] {
          width: 94px;
          height: 5px;
          background-color: $col-white;
          @include border-radius(100px);
        }
      }
    }

    &-subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 1.5rem;
      text-align: center;
      color: $col-white;
      margin-bottom: 40px;
    }

    &-title {
      font-style: normal;
      font-weight: 500;
      font-size: 3rem;
      line-height: 3.625rem;
      text-align: center;
      color: $col-white;
      margin-bottom: 16px;
      // text-transform: capitalize;
      max-width: 64rem;
      font-family: $Tiempos;

      @include respond(big-tab) {
        font-size: 2.25rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.6875rem;
        letter-spacing: 0em;
        text-align: center;
      }
    }

    &-rsvp-button {
      &__act {
        font-size: 16px;
        line-height: 19px;
        @include flex-row;
        padding: 12px 24px;
        width: fit-content;
        margin: 0 auto;
        color: $col-black;
        background-color: $col-white;
        @include border-radius(2px);
        text-transform: uppercase;
        font-family: $Tiempos;
      }
    }

    &-show-love-button {
      & button {
        @include flex-row;
        padding: 12px 24px;
        border: 1px solid $col-white;
        @include border-radius(2px);
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.1875rem;
        color: $col-white;
        text-transform: capitalize;
        font-family: $Tiempos;
      }
    }

    &-text {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      @include flex-row;
      z-index: 4;

      &-button {
        width: 100%;
        @include flex-row;

        &-container {
          @include grid-column(2, auto);
          grid-column-gap: 10px;
        }
      }
    }
  }
}
