@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.invitation {
  &--content {
    background-color: $col-white;
    padding: 40px;
    height: auto;
    width: 551px;
    @include column;
    gap: 20px;
    border-radius: 5px;
    max-height: 85vh;
    overflow-y: auto;
    padding: 40px;

    @include respond(phone) {
      padding: 48px 32px;
      max-height: auto;
      height: 100vh;
      height: -moz-available;
      height: -webkit-fill-available;
      height: calc(100vh - calc(100vh - 100%));
      width: 100vw;
      width: -moz-available;
      width: -webkit-fill-available;
      border-radius: 0px;
      @include column;
      gap: 0.75rem;
      @include z-index(fill-page);
    }

    &__top {
      @include column;
      justify-content: flex-start;
      gap: 0.75rem;
      align-items: center;

      p {
        font-size: 16px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
      }

      h2 {
        font-weight: 400;
        font-size: 30px;
        line-height: 36px;
      }

      h5 {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
      }
    }

    &__image {
      width: 80%;
      margin: 0 auto;
      height: 500px;
      overflow: hidden;
      filter: drop-shadow(0px 3px 5px rgba(9, 30, 66, 0.2))
        drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.31));

      @include respond(mini-laptop) {
        height: 50vh;
        // margin-top: 2rem;
      }
      @include respond(tab) {
        // margin-top: 2rem;
      }
    }

    &__bottom {
      @include flex-row;

      z-index: 3;
      gap: 1rem;
      flex-wrap: wrap;
      margin: 0rem auto;

      // div {
      //     flex: 1;
      // }
    }

    &__sub {
      display: flex;
      height: fit-content;
      margin-bottom: 0.75rem;
    }

    &-form {
      &_container {
        margin-bottom: 2rem;
        @include grid-row(auto-fill, auto);
        grid-row-gap: 18px;
      }
    }
    &-btnContainer {
      display: flex;
      div {
        margin-left: auto;
        button {
          width: auto;
        }
      }
    }
  }
}
