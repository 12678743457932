@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.cashGift {
  &--content {
    background-color: $col-white;
    padding: 40px;
    height: auto;
    width: 551px;
    @include column;
    gap: 20px;
    border-radius: 5px;
    max-height: 85vh;
    overflow-y: auto;
    padding: 40px;

    @include respond(phone) {
      padding: 48px 32px;
      height: 100vh;
      max-height: auto;
      height: -moz-available;
      height: -webkit-fill-available;
      height: calc(100vh - calc(100vh - 100%));
      width: 100vw;
      width: -moz-available;
      width: -webkit-fill-available;
      border-radius: 0px;
      @include column;
      gap: 0.75rem;
      @include z-index(fill-page);
    }

    &__top {
      @include column;
      gap: 0.75rem;
      margin-bottom: 16px;

      h1 {
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
      }
    }

    &__sub {
      display: flex;
      height: fit-content;
      margin-bottom: 0.75rem;
    }

    &-form {
      @include column;
      width: 100%;
      height: auto;

      &__errors {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.1875rem;
        text-align: left;
        display: flex;
        align-items: center;
        margin-top: 5px;
        color: $col-fail;
      }

      &__note {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        color: $col-secondary-9;
      }

      &__field {
        position: relative;
        padding: 0.8rem 1.5rem;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        padding-right: 3rem;
        border: 1px solid $col-secondary;
        background-color: $col-secondary-2;
        width: 100%;

        @include border-radius(2px);
        @include flex-space-between;

        &::placeholder {
          font-style: normal;
          font-weight: normal;
          font-size: 1rem;
          line-height: 1.375rem;
          color: $col-secondary-9;
        }

        &:focus,
        &:focus-visible,
        &:focus-within {
          position: relative;
          background-color: $col-secondary-2;

          @include border-radius(2px);
          @include row;
        }

        &:focus,
        &:focus-visible,
        &:focus-within {
          border-bottom: 1px solid #34544f;
          border-radius: 0px 0px 2px 2px;
        }
      }

      &_container {
        margin-bottom: 0.5rem;

        &__group {
          @include flex-row;
          flex-wrap: wrap;
          margin-bottom: 2.5rem;
          gap: 0.5rem;

          &--btn {
            border: 1px solid #4d4d4d;
            border-radius: 4px;
            padding: 12px 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #001d3d;

            @include flex-row;
          }
        }
      }
    }
    &-btnContainer {
      display: flex;
      gap: 1.2rem;
      flex-wrap: wrap;

      & button {
        flex: 1;
      }

      div {
        display: flex;
        gap: 1rem;
        align-items: center;
      }

      &-p {
        white-space: nowrap;
      }
    }

    &__optionView {
      height: 50vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 3rem;

      .option {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2.5rem;
        cursor: pointer;

        span {
          font-family: $Tiempos;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $col-black;
        }
      }
    }

    &__foreignDetails {
      padding: 0 2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @include respond(phone) {
        padding: 0;
      }

      &__detail {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .name {
          font-family: $Tiempos;
          font-weight: 400;
          font-size: 12px;
          color: $col-black;
          display: flex;
          align-items: center;
        }

        .value {
          font-family: $Tiempos;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: $col-black;
          display: grid;
          align-items: center;
          grid-template-columns: 80% 20%;
          gap: 0.5rem;
          cursor: pointer;
        }
      }
    }
  }
}

.input {
  @include column;
  width: 100%;
  height: auto;

  &__errors {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1875rem;
    text-align: left;
    display: flex;
    align-items: center;
    margin-top: 5px;

    &[data-error="true"] {
      color: $col-fail;
    }

    &[data-success="true"] {
      color: $col-secondary-10;
    }
  }

  & label {
    margin-bottom: 0.25rem;
    color: $col-black;
    font-size: 16px;
    line-height: 22px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &__tel {
    @include flex-row;
    border-right: 1px solid $col-secondary-7;
    padding: 0 1.5rem;
    line-height: 1.3638rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    color: $col-secondary-9;
  }

  &__field {
    position: relative;
    background-color: transparent;
    border: 1px solid transparent;
    width: 100%;
    // height: 50px;

    &:focus,
    &:focus-visible,
    &:focus-within {
      position: relative;
      background-color: $col-secondary-2;

      @include border-radius(2px);
      @include row;
    }

    &:focus,
    &:focus-visible,
    &:focus-within {
      border-bottom: 1px solid #34544f;
      border-radius: 0px 0px 2px 2px;
    }

    & input {
      width: 100%;
      height: 100%;
      outline: 0;
      padding: 0.8rem 1.5rem;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      padding-right: 3rem;
      border: 1px solid $col-secondary;
      background-color: $col-secondary-2;

      @include border-radius(2px);
      @include flex-space-between;

      &::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.375rem;
        color: $col-secondary-9;
      }
    }
  }
}
