@import "@styles/abstracts/variables.scss";
@import "@styles/abstracts/mixins.scss";

.header {
  display: grid;
  grid-template-columns: 80px 1fr 80px;
  width: 100%;
  min-height: 64px;
  box-shadow: 0px 6px 24px rgba(237, 237, 237, 0.25);
  background-color: transparent;

  @include respond(big-tab) {
    grid-template-columns: 10px 1fr 10px;
  }

  &__header {
    width: 100%;
    height: 100%;
    grid-column: 2/3;
    @include flex-space-between;

    &-notifications {
      width: 26px;
      height: 26px;
      cursor: pointer;

      & svg {
        width: 100%;
        height: 100%;
      }
      &-button {
        // margin-left: 45px;
        // width: 145px;

        @include respond(big-tab) {
          display: none;
        }

        &__btn {
          font-size: 16px;
          width: 100%;
          padding: 8px 16px;
          background: #fff8e6;
          border: 1px solid #ffb800;
          border-radius: 4px;
          @include flex-row;
          @include border-radius(4px);
          @include transition;

          & span {
            color: $col-black;
            font-family: $Tiempos;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
          }
          &:disabled,
          &[disabled] {
            background: #cccccc;
            border: 1px solid #cccccc;
            cursor: not-allowed;

            & span {
              color: $col-white;
            }

            &:hover,
            &:focus-within {
              background: #cccccc;
              border: 1px solid #cccccc;

              & span {
                color: $col-white;
              }
            }
          }

          //   &:hover,
          //   &:focus-within {
          //   }
        }
      }
      &-container {
        @include row;
        align-items: center;
        gap: 1rem;
      }
    }

    &-title {
      @include row;
      align-items: center;

      &-text {
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        letter-spacing: 0em;
        text-align: left;
        font-family: $Tiempos;
      }

      &-icon {
        width: 40px;
        height: 40px;

        & svg {
          width: 100%;
          height: 100%;
        }
      }

      &-text {
        margin-left: 10px;
        color: $col-black;
      }
    }
  }
}
