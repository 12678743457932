@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.dialog {
  &__overlay {
    position: fixed;
    inset: 0;
    top: 0;
    left: 0;
    // height: 100vh;
    // height: -moz-available;
    // height: -webkit-fill-available;
    height: calc(100vh - calc(100vh - 100%));
    width: 100vw;
    width: -moz-available;
    width: -webkit-fill-available;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2147483645;
    overflow-y: auto;
    gap: 0.75rem;

    @include flex-column;
    @include overlay-animation;

    @include respond(phone) {
      background-color: #fff;
    }
  }
  &__fill {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    // width: 100vw;
    // max-height: 85vh;

    @include flex-column;

    z-index: 2147483645;

    @include respond(phone) {
      background-color: #fff;
      height: calc(100vh - calc(100vh - 100%));
      // height: 100vh;
      // height: -moz-available;
      // height: -webkit-fill-available;
      width: 100vw;
      // width: -moz-available;
      // width: -webkit-fill-available;
    }

    &:focus {
      outline: none;
    }
  }

  &__content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    // width: 100vw;
    // max-height: 85vh;

    @include flex-column;

    z-index: 2147483645;

    @include overlay-content-show;

    @include respond(phone) {
      background-color: #fff;
      height: calc(100vh - calc(100vh - 100%));
      // height: 100vh;
      // height: -moz-available;
      // height: -webkit-fill-available;
      width: 100vw;
      // width: -moz-available;
      // width: -webkit-fill-available;
    }

    &:focus {
      outline: none;
    }
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: flex-end;
    align-self: flex-end;
    height: 40px;
    width: 40px;
    border: 1px solid transparent;
    border-radius: 50%;
    background-color: $col-white;
    margin-bottom: 1rem;
    z-index: 2;

    @include respond(tab) {
      position: absolute;
      z-index: 1001;
      top: 2rem;
      right: 1rem;
    }

    @include respond(phone) {
      position: absolute;
      z-index: 1001;
      top: 2.25rem;
      right: 1rem;
    }

    &--fill {
      z-index: 2147483645;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      justify-self: flex-end;
      align-self: flex-end;
      height: 40px;
      width: 40px;
      border: 1px solid transparent;
      border-radius: 50%;
      background-color: $col-white;
      right: 1rem;
      top: 2rem;

      @include respond(tab) {
        position: absolute;
        z-index: 1001;
        top: 2rem;
        right: 1rem;
      }

      @include respond(phone) {
        position: absolute;
        z-index: 1001;
        top: 2.25rem;
        right: 1rem;
      }
    }
  }

  &__trigger {
    border: 1px solid transparent !important;
    border-radius: 0 !important;
    padding: 0 !important;
    background: transparent !important;
    width: fit-content;

    span {
      color: initial;
    }
  }

  &__trigger:focus {
    border: 1px transparent;
  }

  &__trigger:focus-visible {
    border: 1px dotted $col-black !important;
  }
}
