@use "sass:math";

$z-index: (
  fill-page: 1000,
  modal: 50,
  dropdown-menu: 40,
  navigation: 30,
  underlay: 20,
  overlay: 10,
  normal: 5,
  behind: -1,
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin floating-transition {
  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes slideLeftAndFade {
    from {
      opacity: 0;
      transform: translateX(2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes slideUpAndFade {
    from {
      opacity: 0;
      transform: translateY(2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes slideRightAndFade {
    from {
      opacity: 0;
      transform: translateX(-2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    animation-fill-mode: backwards;
    will-change: transform, opacity;

    &[data-side="top"] {
      animation-name: slideDownAndFade;
    }
    &[data-side="right"] {
      animation-name: slideLeftAndFade;
    }
    &[data-side="bottom"] {
      animation-name: slideUpAndFade;
    }
    &[data-side="left"] {
      animation-name: slideRightAndFade;
    }
  }
}

@mixin overlay-animation {
  @keyframes overlayAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    animation: overlayShow 200ms cubic-bezier(0.16, 1, 0.3, 1) backwards;
  }
}

@mixin overlay-content-show {
  @keyframes overlayContentShow {
    from {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.96);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    animation: overlayContentShow 200ms cubic-bezier(0.16, 1, 0.3, 1) backwards;
  }
}

@mixin z-index($key) {
  z-index: z-index($key);
}
// Z INDEX

// MOBILE FIRST MEDIA QUERY
@mixin respond($breakpoint) {
  @if $breakpoint==mini-phone {
    @media only screen and (max-width: 21.875em) {
      @content;
    }

    // < 350px
  }

  @if $breakpoint==smallest-phone {
    @media only screen and (max-width: 23.4375em) {
      @content;
    }

    // < 375px
  }

  @if $breakpoint==smaller-phone {
    @media only screen and (max-width: 26.5625em) {
      @content;
    }

    // < 425px
  }

  @if $breakpoint==small-phone {
    @media only screen and (max-width: 31.25em) {
      @content;
    }

    // < 500px
  }

  @if $breakpoint==phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    }

    // < 600px
  }

  @if $breakpoint==tab {
    @media only screen and (max-width: 48em) {
      @content;
    }

    // < 768px
  }

  @if $breakpoint==big-tab {
    @media only screen and (max-width: 56.25em) {
      @content;
    }

    // < 900px
  }

  @if $breakpoint==biggest-tab {
    @media only screen and (max-width: 1024px) {
      @content;
    }

    // < 900px
  }

  @if $breakpoint==mini-laptop {
    @media only screen and (max-width: 78.75em) {
      @content;
    }

    // < 1060px
  }

  @if $breakpoint==max-laptop {
    @media only screen and (max-width: 95em) {
      @content;
    }

    // < 1430px
  }
}

@mixin position-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin position-fixed-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin border-radius($pixel...) {
  border-radius: $pixel;
  -webkit-border-radius: $pixel;
  -moz-border-radius: $pixel;
  -ms-border-radius: $pixel;
  -o-border-radius: $pixel;
}

@mixin filter($value) {
  filter: $value();
  -webkit-filter: $value();
}

//   SCALE ELEMENT FOR HOVER EFFECT
@mixin scale($scale) {
  transform: scale($scale);
  -webkit-transform: scale($scale);
  -moz-transform: scale($scale);
  -ms-transform: scale($scale);
  -o-transform: scale($scale);
}

@mixin transition {
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

@mixin transition-color {
  transition: color 0.3s ease-out;
  -webkit-transition: color 0.3s ease-out;
  -moz-transition: color 0.3s ease-out;
  -ms-transition: color 0.3s ease-out;
  -o-transition: color 0.3s ease-out;
}

// FLEX FLOW
@mixin flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

// FLEX FLOW WITH CUSTOM PROPERTIES
@mixin flex-row-custom($jc: center, $ai: center, $fd: row, $fw: nowrap) {
  display: flex;
  flex-direction: $fd;
  align-items: $ai;
  justify-content: $jc;
  flex-wrap: $fw;
}

// FLEX SPACE BETWEEN
@mixin flex-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

// FLEX ROW-ALONE
@mixin row {
  display: flex;
  flex-direction: row;
}

//FLEX COLUMN
@mixin flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// FLEX COLUMN-ALONE
@mixin column {
  display: flex;
  flex-direction: column;
}

// GRID COLUMN-ALONE
@mixin grid-column($repeat, $size) {
  display: grid;
  grid-template-columns: repeat($repeat, $size);
}

// GRID ROW-ALONE
@mixin grid-row($repeat, $size) {
  display: grid;
  grid-template-rows: repeat($repeat, $size);
}

//GRID (ROW AND COLUMN)
@mixin grid($repeat, $size) {
  display: grid;
  grid-template-columns: repeat($repeat, $size);
  grid-template-rows: repeat($repeat, $size);
}

@mixin grid-center {
  display: grid;
  place-items: center;
}

@mixin placeholder($color) {
  /* WebKit, Edge */
  &::-webkit-input-placeholder {
    color: $color;
  }

  /* Firefox 4-18 */
  &:-moz-placeholder {
    color: $color;
  }

  /* Firefox 19+ */
  &::-moz-placeholder {
    color: $color;
  }

  /* IE 10-11 */
  &:-ms-input-placeholder {
    color: $color;
  }

  /* Edge */
  &::-ms-input-placeholder {
    color: $color;
  }

  /* MODERN BROWSER */
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &::-ms-input-placeholder {
    color: $color;
  }
  &::placeholder {
    color: $color;
  }
}

@mixin skeleton {
  background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    linear-gradient(lightgray 100%, transparent 0);

  background-repeat: no-repeat;
  background-size: 100px 100%, 100% 100%;
  background-position: 0 0, 0 0;

  animation: shine 1s infinite;

  @keyframes shine {
    to {
      background-position: 300% 0, 0 0;
    }
  }
}

@mixin center-column {
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  @include respond(big-tab) {
    grid-template-columns: 1fr;
  }
}

@mixin place-top-right {
  position: absolute;
  top: 0;
  right: 0;
}

@mixin place-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}
