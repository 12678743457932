@use "@styles/abstracts/variables.scss" as *;
@use "@styles/abstracts/mixins.scss" as *;

.button {
  &__primary {
    width: 100%;
    border: 1px solid $col-black;
    background-color: transparent;
    padding: 12px 24px;
    @include flex-row;
    @include border-radius(2px);
    @include transition;

    & span {
      color: $col-black;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.1875rem;
      text-transform: uppercase;
      font-family: $Tiempos;
    }
    &:disabled,
    &[disabled] {
      background: #cccccc;
      border: 1px solid #cccccc;
      cursor: not-allowed;

      & span {
        color: $col-white;
      }

      &:hover,
      &:focus-within {
        background: #cccccc;
        border: 1px solid #cccccc;

        & span {
          color: $col-white;
        }
      }
    }

    &:hover,
    &:focus-within {
      background-color: $col-secondary-2;
    }
  }

  &__secondary {
    width: 100%;
    padding: 12px 32px;
    background: #fff8e6;
    border: 1px solid #ffb800;
    border-radius: 4px;
    @include flex-row;
    @include border-radius(4px);
    @include transition;

    & span {
      color: $col-black;
      font-family: $Tiempos;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }
    &:disabled,
    &[disabled] {
      background: #efeff0;
      border: 1px solid #cccccc;
      cursor: not-allowed;

      & span {
        color: #a1a1aa;
      }

      &:hover,
      &:focus-within {
        background: #cccccc;
        border: 1px solid #cccccc;

        & span {
          color: $col-white;
        }
      }
    }

    &:hover,
    &:focus-within {
      // background: #ffb800;
    }
  }

  &__tertiary {
    width: 100%;
    padding: 12px 32px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 4px;
    @include flex-row;
    @include border-radius(4px);
    @include transition;

    & span {
      color: $col-black;
      font-family: $Tiempos;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }
    &:disabled,
    &[disabled] {
      background: #cccccc;
      border: 1px solid #cccccc;
      cursor: not-allowed;

      & span {
        color: $col-white;
      }

      &:hover,
      &:focus-within {
        background: #cccccc;
        border: 1px solid #cccccc;

        & span {
          color: $col-white;
        }
      }
    }

    &:hover,
    &:focus-within {
      background-color: $col-secondary-2;
    }
  }

  &__other {
    width: 100%;
    padding: 12px 32px;
    background: transparent;
    border: 1px solid #edac06;
    border-radius: 4px;
    @include flex-row;
    @include border-radius(4px);
    @include transition;

    & span {
      color: $col-black;
      font-family: $Tiempos;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }
    &:disabled,
    &[disabled] {
      background: #cccccc;
      border: 1px solid #cccccc;
      cursor: not-allowed;

      & span {
        color: $col-white;
      }

      &:hover,
      &:focus-within {
        background: #cccccc;
        border: 1px solid #cccccc;

        & span {
          color: $col-white;
        }
      }
    }

    &:hover,
    &:focus-within {
      background-color: $col-secondary-2;
    }
  }

  &__another {
    width: 100%;
    padding: 12px 32px;
    background: #ffb800;
    border: 1px solid #ffb800;
    border-radius: 4px;
    @include flex-row;
    @include border-radius(4px);
    @include transition;

    & span {
      color: $col-black;
      font-family: $Tiempos;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }

    &:disabled,
    &[disabled] {
      background: #cccccc;
      border: 1px solid #cccccc;
      cursor: not-allowed;

      & span {
        color: $col-white;
      }

      &:hover,
      &:focus-within {
        background: #cccccc;
        border: 1px solid #cccccc;

        & span {
          color: $col-white;
        }
      }
    }

    &:hover,
    &:focus-within {
      background-color: $col-secondary-2;
    }
  }
}
